import { BrowserRouter } from 'react-router-dom'
import Header from './components/Header'

function RoutesApp(){
    return(
        <BrowserRouter>
            <Header/>
        </BrowserRouter>
    )
}

export default RoutesApp